<template>
    <div class="signUP-admin">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    
                    <div class="signUp-admin-right signIn-admin-right p-md-40 p-10">
                        <div class="row justify-content-center">
                            <div class="col-xl-7 col-lg-8 col-md-12">
                                <h2>
                                    <center>
                                        MIGRASI DARI STARSENDER V2
                                    </center>
                                </h2>
                                <div v-if="step == 1" class="edit-profile mt-md-25 mt-0">
                                    <div class="card border-0">
                                        <div class="card-header border-0 pb-md-15 pb-10 pt-md-20 pt-10">
                                            <div class="edit-profile__title">
                                                <h6>1. Silahkan Login Menggunakan Akun STARSENDER V2</h6>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="edit-profile__body">
                                                <form method="POST" v-on:submit.prevent="signIn">
                                                    <div class="form-group mb-20">
                                                        <label for="username">
                                                            {{ $t("auth.username_or_email") }}
                                                        </label>
                                                        <input type="text" class="form-control" id="email"
                                                            placeholder="Email@example.com" v-model="form.email"
                                                            required />
                                                        <span class="invalid-feedback" role="alert">
                                                            <strong></strong>
                                                        </span>
                                                    </div>
                                                    <div class="form-group mb-15">
                                                        <label for="password-field">
                                                            {{ $t("auth.password") }}
                                                        </label>
                                                        <div class="position-relative">
                                                            <input id="password-field" :type="passwordFieldType"
                                                                class="form-control" placeholder="Password"
                                                                v-model="form.password" required />
                                                            <a v-on:click="togglePassword"
                                                                class="fa fa-fw fa-eye-slash text-light fs-16 field-icon toggle-password2"></a>
                                                            <span class="invalid-feedback" role="alert">
                                                                <strong></strong>
                                                            </span>
                                                        </div>
                                                    </div> 
                                                    <div
                                                        class="button-group d-flex pt-1 justify-content-md-start justify-content-center">
                                                        <button v-if="isLoading"
                                                            class="btn btn-primary btn-block btn-default btn-squared text-capitalize lh-normal px-50 py-15 signIn-createBtn"
                                                            disabled>
                                                            <div class="spinner-border spinner" role="status"></div>
                                                            {{ $t("auth.loading") }}
                                                        </button>
                                                        <button v-else
                                                            class="btn btn-primary btn-block btn-default btn-squared text-capitalize lh-normal px-50 py-15 signIn-createBtn">
                                                            {{ $t("auth.sign_in") }}
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="step == 2" class="edit-profile mt-md-25 mt-0">
                                    <div class="card border-0">
                                        <div class="card-header border-0 pb-md-15 pb-10 pt-md-20 pt-10">
                                            <div class="edit-profile__title">
                                                <h6>2. Data Siap Dimigrasi</h6>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="edit-profile__body">
                                                <b>CATATAN : </b>
                                                <p>
                                                - Data anda di STARSENDER V2 tetap aman dan masih dapat diakses (dalam jangka waktu tertentu) walupun sudah dimigrasi
                                                <br>
                                                - Migrasi mungkin membutuhkan waktu, anda sudah dapat menggunakan v3 walupun migrasi sedang dilakukan
                                                <br>
                                                - Semua device membutuhkan scan ulang, karena server device harus dipindah ke server STARSENDER V3
                                                <br>
                                                - Jika ada pesan error silahkan kontak admin
                                                </p>
                                                <br>
                                                <div v-if="!migrationStart">
                                                    <button v-on:click="startMigration" class="btn btn-primary btn-block btn-default btn-squared text-capitalize lh-normal px-50 py-15 signIn-createBtn">Mulai Migrasi</button>
                                                    <button v-on:click="back" class="btn btn-danger btn-block btn-default btn-squared text-capitalize lh-normal px-50 py-15 signIn-createBtn">Kembali</button>

                                                </div>
                                                <textarea style="height:120px" v-else class="form-control" cols="30" rows="10" v-model="progres" readonly></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        components: {},
        data() {
            return {
                step: 1,
                form: {
                    email: "",
                    password: "",
                },
                token: "",
                migrationStart: false,
                progres: "",
                passwordFieldType: "password",
                isLoading: false,
                loggedInEmail: "",
                intv: null,
                int: {
                    counter: 0,
                    last: ""
                }
            };
        },
        beforeDestroy() {
            clearInterval(this.intv)
        },
        mounted() {},
        methods: {
            async signIn() {
                this.isLoading = true;

                try {
                    let login = await axios.post('https://starsender.online/api/migration/login',{
                        email: this.form.email,
                        password: this.form.password
                    })

                    if(login.data.status == 0){
                        this.$toast.error("Login Gagal")
                        this.isLoading = false
                        return
                    }

                    //CHECK MIGRATION STATUS
                    let track = await axios.get('https://migrator.starsender.online/status?email=' + login.data.email)

                    this.loggedInEmail = login.data.email

                    if(track?.data?.status == false){
                        this.token = login.data.token
                        this.$toast.success("Login Sukses")
                        this.step = 2
                    }else{
                        this.$toast.success("Migrasi sedang berjalan")
                        this.step = 2
                        this.progres = ''
                        this.migrationStart = true

                        this.track(this.loggedInEmail)
                        let eml = this.loggedInEmail

                        this.intv = setInterval(() => {
                            this.track(eml)
                        }, 10000)
                    }


                    // await this.$store.dispatch("auth_login", this.form);
                    // await this.$root.$emit("login", true);
                    // //this.$toast.success("Login success");
                    // await this.$router.push("/dashboard");
                    //window.location.reload()
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
                this.isLoading = false;
            },
            async startMigration(){
                this.migrationStart = true

                if(!this.token) return this.$toast.error("Silakan refresh halaman")
                await axios.post('https://migrator.starsender.online/migrate',{
                    token: this.token,
                })

                // let trackingID = migrate.data.tracking_id

                this.progres = ''

                this.track(this.loggedInEmail)
                let eml = this.loggedInEmail

                this.intv = setInterval(() => {
                    this.track(eml)
                }, 10000)
            },
            async track(id){
                let track = await axios.get('https://migrator.starsender.online/status?email=' + id)

                //INTERVAL CHECK
                if(this.int.counter >= 3){
                    clearInterval(this.intv)
                    console.log("INTERVAL CLEARED")
                } 

                if(this.int.last != track.data){
                    this.int.last = track.data
                    this.int.counter = 0
                }else{
                    this.int.counter++
                }

                if(track.data.status == false) return
                this.progres = track.data + '\n' + this.progres

                if(track.data != "STARTING"){
                    this.$toast.success("Migrasi sedang dilakukan di background anda sudah dapat menggunakan v3 sambil menunggu migrasi selesai")
                    this.$router.push('/login')
                }

                if(track.data == "SUCCESS"){
                    this.$toast.success("Migrasi sukses silakan login")
                    this.$router.push('/login')
                }
            },
            togglePassword() {
                if (this.passwordFieldType == "password") {
                    this.passwordFieldType = "text"
                } else {
                    this.passwordFieldType = "password"
                }
            },
            back(){
                this.step = 1;
                this.form.email = ""
                this.form.password = ""
                this.token = ""
                this.migrationStart = false
                this.progres = ""
            }
        },
    };
</script>

<style scoped>
    .spinner {
        height: 1rem !important;
        width: 1rem !important;
    }

    .btn {
        line-height: 1rem !important;
    }
    .signUP-admin {
        background-color: #f4f5f7;
    }
</style>